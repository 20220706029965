<template>
  <div class="home">
    <h1>Bienvenid@</h1>
  </div>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
  name: 'HomeView',
  components: {

  },
  setup () {

  }
}
</script>
