import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

//axios
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    }
})

createApp(App).use(router).use(vuetify).use(VueAxios, axios).use(VCalendar, {}).mount('#app')
