<template>
  <v-app>
    <v-container>
      <v-bottom-navigation grow>
        <v-btn value="home" to="/" exact>
          <v-icon>mdi-home</v-icon>
          Home
        </v-btn>
<!--        <v-btn value="grid" to="/grid" exact>-->
<!--          <v-icon>mdi-grid-large</v-icon>-->
<!--          Grid-->
<!--        </v-btn>-->
        <v-btn value="calendar" to="/calendar" exact>
          <v-icon>mdi-calendar</v-icon>
          Calendar
        </v-btn>
        <v-btn value="about" to="/about" exact>
          <v-icon>mdi-information</v-icon>
          About
        </v-btn>
        <v-btn @click="toggleTheme">
          <v-icon>mdi-theme-light-dark</v-icon>
          Cambiar Tema
        </v-btn>
      </v-bottom-navigation>
      <router-view/>
    </v-container>
  </v-app>
</template>
<script>
import {useTheme} from 'vuetify'
import {onMounted} from "vue";

export default {
  name: 'Main',
  components: {},
  setup() {
    const theme = useTheme()

    onMounted(async() => {
      const ancho = screen.width;
      const largo = screen.height;

      document.body.style.backgroundImage = `url("https://random.imagecdn.app/v1/image?width=${ancho}&height=${largo}&format=image")`;
    })

    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  opacity: .93;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
